<template>
  <v-container>
    <v-form>
      <h3>{{ $t('wizard.driverForm') }}</h3>
      <v-text-field
        v-model="formData.name"
        :label="$t('wizard.name') + '*'"
        clearable
        :error-messages="nameErrors"
        @blur="$v.formData.name.$touch()"
        @input="$v.formData.name.$touch()"
      >
      </v-text-field>
      <v-text-field
        v-model="formData.email"
        :label="$t('wizard.email')"
        clearable
      ></v-text-field>
      <v-text-field
        v-model="formData.phone"
        :label="$t('wizard.phone')"
        clearable
      ></v-text-field>
      <v-divider></v-divider>
      <v-btn
        color="success"
        class="ma-4"
        @click="saveDriver"
        :disabled="nameErrors.length > 0"
        >{{ $t('wizard.save') }}</v-btn
      >
      <v-btn color="warning" class="ma-4" @click="$emit('toggle')">{{
        $t('wizard.cancel')
      }}</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { countries } from '../utils/countries'
export default {
  data() {
    return {
      countries: countries,
      datepicker_show: false,
      formData: {
        name: '',
        birthDate: new Date(),
        email: '',
        phone: '',
      },
    }
  },
  validations: {
    formData: {
      name: {
        required: required,
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.formData.name.$dirty) return errors
      !this.$v.formData.name.required &&
        errors.push(this.$i18n.t('validations.driverRequired'))
      return errors
    },
  },
  methods: {
    saveDriver() {
      this.$v.formData.name.$touch()
      if (!this.$v.$anyError && this.formData.name !== '') {
        this.$emit('saveDriver', this.formData)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
