<template>
  <v-container>
    <v-form>
      <h1>Owner</h1>
      <v-text-field dense v-model="company" label="Company name" clearable>
      </v-text-field>
      <h2 class="text-left">Address</h2>
      <v-text-field dense label="Street" clearable> </v-text-field>
      <v-row>
        <v-col>
          <v-text-field dense label="City" clearable> </v-text-field>
        </v-col>
        <v-col>
          <v-text-field dense label="ZIP" clearable> </v-text-field>
        </v-col>
      </v-row>
      <v-autocomplete
        label="Country"
        :items="countries"
        :menu-props="{ maxHeight: 125 }"
      >
      </v-autocomplete>
      <h2 class="text-left">Contact info</h2>
      <v-text-field dense label="Code" clearable></v-text-field>
      <v-text-field dense label="Phone" clearable></v-text-field>
      <v-text-field dense label="Website" clearable></v-text-field>
      <v-text-field dense label="Email" clearable></v-text-field>
      <v-divider></v-divider>
      <v-btn color="success" class="ma-4" @click="$emit('saveOwner', company)"
        >Save</v-btn
      >
      <v-btn color="warning" class="ma-4" @click="$emit('toggle')"
        >Cancel</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
import { countries } from '../utils/countries'
export default {
  data() {
    return {
      countries: countries,
      company: '',
    }
  },
}
</script>

<style lang="scss" scoped></style>
