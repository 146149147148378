<template>
  <v-container>
    <v-form>
      <h3 class="text-left">{{ $t('wizard.insuredVehicleForm') }}</h3>

      <v-text-field
        v-model="formData.registration"
        :label="$t('wizard.registration') + '*'"
        placeholder="AAA000"
        clearable
        :error-messages="registrationErrors"
        @blur="$v.formData.registration.$touch()"
        @input="
          (event) => {
            removeWhiteSpace(event)
            $v.formData.registration.$touch(event)
          }
        "
      >
      </v-text-field>
      <v-text-field dense v-model="make" :label="$t('wizard.make')" clearable>
      </v-text-field>
      <v-text-field dense v-model="model" :label="$t('wizard.model')" clearable>
      </v-text-field>
      <v-text-field
        dense
        v-model="formData.year"
        :label="$t('wizard.modelYear')"
        placeholder="2022"
        clearable
        type="number"
      ></v-text-field>
      <v-text-field
        dense
        v-model="formData.vin"
        label="VIN"
        placeholder="JM3KE4CY5F0432716"
        clearable
      >
      </v-text-field>
      <v-divider></v-divider>
      <v-btn
        color="success"
        class="ma-4"
        @click="saveVehicle"
        :disabled="registrationErrors.length > 0"
        >{{ $t('wizard.save') }}</v-btn
      >
      <v-btn color="warning" class="ma-4" @click="$emit('toggle')">{{
        $t('wizard.cancel')
      }}</v-btn>
    </v-form>
    <Modal
      v-if="showOwnerModal"
      :show="showOwnerModal"
      @toggle="toggleOwnerModal"
      title="Owner form"
    >
      <OwnerForm @saveOwner="saveOwnerFormModal" @toggle="toggleOwnerModal" />
    </Modal>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import OwnerForm from './OwnerForm.vue'
import Modal from './Modal.vue'
export default {
  data() {
    return {
      selectedOwner: '',
      showOwnerModal: false,
      make: '',
      model: '',
      formData: {
        registration: '',
        vin: '',
        year: 2022,
      },

      // owners: [],
    }
  },
  validations: {
    formData: {
      registration: {
        required: required,
      },
    },
  },
  components: {
    OwnerForm,
    Modal,
  },
  methods: {
    toggleOwnerModal() {
      this.showOwnerModal = !this.showOwnerModal
    },
    saveOwnerFormModal(values) {
      this.owners.push({
        text: values,
        value: values,
      })
      this.selectedOwner = values
      this.toggleOwnerModal()
    },
    saveVehicle() {
      this.$v.formData.registration.$touch()
      if (!this.$v.$anyError && this.formData.registration !== '') {
        this.$emit('saveVehicle', this.formData)
      }
    },
    removeWhiteSpace() {
      if (this.formData.registration) {
        this.formData.registration = this.formData.registration.replace(
          /\s/g,
          ''
        )
      }
    },
  },
  computed: {
    insuredvehicle() {
      return `${this.make}/${this.model}/${this.license_no}`
    },
    registrationErrors() {
      const errors = []
      if (!this.$v.formData.registration.$dirty) return errors
      !this.$v.formData.registration.required &&
        errors.push(this.$i18n.t('validations.vehicleRegistrationRequired'))
      return errors
    },
  },
}
</script>

<style lang="scss" scoped></style>
