<template>
  <v-container>
    <v-form>
      <h3>Insurance policy</h3>
      <v-row class="mt-3">
        <v-col cols="6">
          <v-text-field dense label="Insurance Company" clearable>
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            dense
            label="Country"
            :items="countries"
            :menu-props="{ maxHeight: 125 }"
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field dense label="Intermediary" clearable> </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            dense
            label="Country"
            :items="countries"
            :menu-props="{ maxHeight: 125 }"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-col cols="8">
        <v-text-field
          dense
          v-model="policy_no"
          label="Policy No."
          clearable
        ></v-text-field>
      </v-col>
      <v-divider></v-divider>
      <v-btn
        color="success"
        class="ma-4"
        @click="$emit('savePolicy', policy_no)"
        >Save</v-btn
      >
      <v-btn color="warning" class="ma-4" @click="$emit('toggle')"
        >Cancel</v-btn
      >
    </v-form>
  </v-container>
</template>

<script>
import { countries } from '../utils/countries'
export default {
  data() {
    return {
      countries: countries,
      policy_no: '',
    }
  },
  methods: {
    registerClick() {},
  },
}
</script>

<style lang="scss" scoped></style>
